<template>
    <AutosuggestQuery
        :gql-query="query"
        :render-suggestion="(suggestion) => suggestion.item.city"
        :get-suggestion="(suggestion) => suggestion.item.city"
        v-bind="$attrs"
        @input="$emit('input', $event)"
    />
</template>

<script>
import gql from 'graphql-tag'
import AutosuggestQuery from '@/components/auto-suggest/AutosuggestQuery'

export default {
    name: 'CitySearch',
    components: {
        AutosuggestQuery
    },
    emits: ['input'],
    computed: {
        query() {
            return {
                name: 'findCity',
                query: gql`
                    query FindCity($q: String!) {
                        findCity(q: $q)
                    }
                `
            }
        }
    }
}
</script>
