<template>
    <select v-model="value" class="gb-select" :class="classes">
        <option :value="null" disabled>{{ placeholder }}</option>
        <slot name="elements"></slot>
    </select>
</template>

<script>
export default {
    name: 'Select',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    data() {
        return {
            value: null
        }
    },
    computed: {
        classes() {
            return {
                'gb-select--small': this.small
            }
        }
    },
    watch: {
        value: {
            handler: function() {
                this.$emit('input', this.value)
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.gb-select {
    border: 0;

    border-radius: 5px;
    background: white;

    padding: 0 0.5rem;
    color: $primary;
    font-size: 1.125rem;
    line-height: 1.25rem;
    width: 100%;
    text-align-last: center;
    height: 3.375rem;
    &:active,
    &:focus,
    &:focus-within {
        outline: 0;
        box-shadow: 0 0 0 1px $primary;
    }
    option {
        font-size: 1.125rem;
    }

    &--small {
        font-size: 12px;
        line-height: 12px;
        height: 2rem;
    }
}
</style>
