<template>
    <div class="edit-event bg-light text-primary">
        <div class="edit-event__content">
            <header class="edit-event__header">
                <img
                    src="@/assets/logo_primary.svg"
                    :style="{ width: '4rem', gridColumnStart: 2 }"
                />
                <button
                    class="button button-close"
                    :style="{ justifySelf: 'end' }"
                    @click="$router.go(-1)"
                ></button>
            </header>
            <form class="gb-form" autocomplete="none" @submit.prevent="onSubmit">
                <h2 class="font-weight-medium">Edit your event</h2>
                <fieldset class="gb-form__fieldset">
                    <label>Title</label>
                    <BaseInput
                        id="title"
                        v-model="currentState.name"
                        type="text"
                        placeholder="name of the event"
                    />
                </fieldset>

                <fieldset class="gb-form__fieldset">
                    <label>Description</label>
                    <Textarea
                        v-model="currentState.description"
                        placeholder="write something about the event…"
                    ></Textarea>
                </fieldset>
                <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
                    <div>
                        <label for="start-date">Start date</label>
                        <BaseInput id="start-date" v-model="currentState.startDate" type="date" />
                    </div>
                    <div>
                        <label for="start-time">Start time</label>
                        <BaseInput id="start-time" v-model="currentState.startTime" type="time" />
                    </div>
                </fieldset>
                <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
                    <div>
                        <label for="end-date">End date</label>
                        <BaseInput id="end-date" v-model="currentState.endDate" type="date" />
                    </div>
                    <div>
                        <label for="end-time">End time</label>
                        <BaseInput id="end-time" v-model="currentState.endTime" type="time" />
                    </div>
                </fieldset>

                <fieldset class="gb-form__fieldset">
                    <label for="location">Location</label>
                    <CitySearch
                        id="location"
                        v-model="currentState.location"
                        placeholder="Where is the event?"
                    />
                </fieldset>

                <fieldset class="gb-form__fieldset">
                    <label for="occasion">Occasion</label>
                    <Select v-model="currentState.occasion" placeholder="choose category">
                        <option>Party</option>
                        <option>Birthday</option>
                        <option>Wedding</option>
                    </Select>
                </fieldset>

                <Button
                    v-if="isValid"
                    label="Continue"
                    type="submit"
                    primary
                    big-square-fixed-bottom
                />
            </form>
        </div>
    </div>
</template>

<script>
import Textarea from '@/components/Textarea'
import Select from '@/components/Select'
import BaseInput from '@/components/BaseInput'
import CitySearch from '@/components/auto-suggest/CitySearch'
import Button from '@/components/Button'

import { UPDATE_USER_EVENT } from '@/graphql/mutations/event.js'
import { MY_EVENT } from '@/graphql/queries/event.js'
import { format } from 'date-fns'
export default {
    name: 'EditEvent',
    components: {
        BaseInput,
        Button,
        Textarea,
        CitySearch,
        Select
    },
    apollo: {
        event: {
            query: MY_EVENT,
            variables() {
                return {
                    id: this.$route.params.id
                }
            },
            update(result) {
                const {
                    name,
                    description,
                    eventStart,
                    eventEnd,
                    location,
                    occasion
                } = result.myEvent
                this.initialState = {
                    name,
                    description,
                    startDate: format(new Date(eventStart), 'yyyy-MM-dd'),
                    endDate: format(new Date(eventEnd), 'yyyy-MM-dd'),
                    startTime: format(new Date(eventEnd), 'HH:mm'),
                    endTime: format(new Date(eventEnd), 'HH:mm'),
                    location,
                    occasion
                }
                return result.event
            },
            error(err) {
                console.log({ err })
            }
        }
    },
    data() {
        return {
            initialState: {
                name: null,
                description: null,
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                location: null,
                occasion: null
            },
            currentState: {
                name: null,
                description: null,
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                location: null,
                occasion: null
            }
        }
    },
    computed: {
        isValid() {
            return (
                JSON.stringify(this.initialState) !== JSON.stringify(this.currentState) &&
                this.currentState.location !== null
            )
        }
    },
    watch: {
        initialState() {
            this.currentState = JSON.parse(JSON.stringify(this.initialState))
        },
        'initialState.location': {
            handler(val) {
                if (Object.keys(val).some((key) => key === 'coordinates')) {
                    this.initialState.location = {
                        lat: val.coordinates[1],
                        long: val.coordinates[0]
                    }
                }
            }
        },
        'currentState.location': {
            handler(val) {
                if (Object.keys(val).some((key) => key === 'coordinates')) {
                    this.currentState.location = {
                        lat: val.coordinates[1],
                        long: val.coordinates[0]
                    }
                }
            }
        }
    },
    methods: {
        async onSubmit() {
            try {
                const res = await this.$apollo.mutate({
                    mutation: UPDATE_USER_EVENT,
                    variables: {
                        input: {
                            where: {
                                id: this.$route.params.id
                            },
                            data: {
                                eventStart: new Date(
                                    `${this.currentState.startDate} ${this.currentState.startTime}`
                                ),
                                eventEnd: new Date(
                                    `${this.currentState.endDate} ${this.currentState.endTime}`
                                ),
                                name: this.currentState.name,
                                description: this.currentState.description,
                                location: this.currentState.location
                            }
                        }
                    }
                })

                const { type, errors, updateUserEvent } = res.data
                if (type === 'ResponseError') {
                    console.log('Error when updating event:', { errors })
                }

                console.log('Updated event', updateUserEvent.event)
                this.$router.go(-1)
            } catch (err) {
                console.log({ err })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-event {
    text-align: center;
    padding-bottom: 10rem;
    &__header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        padding: 1rem;
        padding-top: 2rem;
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .edit-event {
        &__content {
            max-width: 400px;
            margin: auto;
        }
    }
}
</style>
